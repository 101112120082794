const option = [
    { id: 'กรุงเทพมหานคร', name: 'กรุงเทพมหานคร'},
    { id: 'สมุทรปราการ', name: 'สมุทรปราการ'},
    { id: 'นนทบุรี', name: 'นนทบุรี'},
    { id: 'ปทุมธานี', name: 'ปทุมธานี'},
    { id: 'พระนครศรีอยุธยา', name: 'พระนครศรีอยุธยา'},
    { id: 'อ่างทอง', name: 'อ่างทอง'},
    { id: 'ลพบุรี', name: 'ลพบุรี'},
    { id: 'สิงห์บุรี', name: 'สิงห์บุรี'},
    { id: 'ชัยนาท', name: 'ชัยนาท'},
    { id: 'สระบุรี', name: 'สระบุรี'},
    { id: 'ชลบุรี', name: 'ชลบุรี'},
    { id: 'ระยอง', name: 'ระยอง'},
    { id: 'จันทบุรี', name: 'จันทบุรี'},
    { id: 'ตราด', name: 'ตราด'},
    { id: 'ฉะเชิงเทรา', name: 'ฉะเชิงเทรา'},
    { id: 'ปราจีนบุรี', name: 'ปราจีนบุรี'},
    { id: 'นครนายก', name: 'นครนายก'},
    { id: 'สระแก้ว', name: 'สระแก้ว'},
    { id: 'นครราชสีมา', name: 'นครราชสีมา'},
    { id: 'บุรีรัมย์', name: 'บุรีรัมย์'},
    { id: 'สุรินทร์', name: 'สุรินทร์'},
    { id: 'ศรีสะเกษ', name: 'ศรีสะเกษ'},
    { id: 'อุบลราชธานี', name: 'อุบลราชธานี'},
    { id: 'ยโสธร', name: 'ยโสธร'},
    { id: 'ชัยภูมิ', name: 'ชัยภูมิ'},
    { id: 'อำนาจเจริญ', name: 'อำนาจเจริญ'},
    { id: 'หนองบัวลำภู', name: 'หนองบัวลำภู'},
    { id: 'ขอนแก่น', name: 'ขอนแก่น'},
    { id: 'อุดรธานี', name: 'อุดรธานี'},
    { id: 'เลย', name: 'เลย'},
    { id: 'หนองคาย', name: 'หนองคาย'},
    { id: 'มหาสารคาม', name: 'มหาสารคาม'},
    { id: 'ร้อยเอ็ด', name: 'ร้อยเอ็ด'},
    { id: 'กาฬสินธุ์', name: 'กาฬสินธุ์'},
    { id: 'สกลนคร', name: 'สกลนคร'},
    { id: 'นครพนม', name: 'นครพนม'},
    { id: 'มุกดาหาร', name: 'มุกดาหาร'},
    { id: 'เชียงใหม่', name: 'เชียงใหม่'},
    { id: 'ลำพูน', name: 'ลำพูน'},
    { id: 'ลำปาง', name: 'ลำปาง'},
    { id: 'อุตรดิตถ์', name: 'อุตรดิตถ์'},
    { id: 'แพร่', name: 'แพร่'},
    { id: 'น่าน', name: 'น่าน'},
    { id: 'พะเยา', name: 'พะเยา'},
    { id: 'เชียงราย', name: 'เชียงราย'},
    { id: 'แม่ฮ่องสอน', name: 'แม่ฮ่องสอน'},
    { id: 'นครสวรรค์', name: 'นครสวรรค์'},
    { id: 'อุทัยธานี', name: 'อุทัยธานี'},
    { id: 'กำแพงเพชร', name: 'กำแพงเพชร'},
    { id: 'ตาก', name: 'ตาก'},
    { id: 'สุโขทัย', name: 'สุโขทัย'},
    { id: 'พิษณุโลก', name: 'พิษณุโลก'},
    { id: 'พิจิตร', name: 'พิจิตร'},
    { id: 'เพชรบูรณ์', name: 'เพชรบูรณ์'},
    { id: 'ราชบุรี', name: 'ราชบุรี'},
    { id: 'กาญจนบุรี', name: 'กาญจนบุรี'},
    { id: 'สุพรรณบุรี', name: 'สุพรรณบุรี'},
    { id: 'นครปฐม', name: 'นครปฐม'},
    { id: 'สมุทรสาคร', name: 'สมุทรสาคร'},
    { id: 'สมุทรสงคราม', name: 'สมุทรสงคราม'},
    { id: 'เพชรบุรี', name: 'เพชรบุรี'},
    { id: 'ประจวบคีรีขันธ์', name: 'ประจวบคีรีขันธ์'},
    { id: 'นครศรีธรรมราช', name: 'นครศรีธรรมราช'},
    { id: 'กระบี่', name: 'กระบี่'},
    { id: 'พังงา', name: 'พังงา'},
    { id: 'ภูเก็ต', name: 'ภูเก็ต'},
    { id: 'สุราษฎร์ธานี', name: 'สุราษฎร์ธานี'},
    { id: 'ระนอง', name: 'ระนอง'},
    { id: 'ชุมพร', name: 'ชุมพร'},
    { id: 'สงขลา', name: 'สงขลา'},
    { id: 'สตูล', name: 'สตูล'},
    { id: 'ตรัง', name: 'ตรัง'},
    { id: 'พัทลุง', name: 'พัทลุง'},
    { id: 'ปัตตานี', name: 'ปัตตานี'},
    { id: 'ยะลา', name: 'ยะลา'},
    { id: 'นราธิวาส', name: 'นราธิวาส'},
    { id: 'บึงกาฬ', name: 'บึงกาฬ'}
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};