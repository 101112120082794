<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>บันทึกข้อมูลรถยนต์</span>
                        </h4>

                        <button class="btn-close" @click="hide(false, null)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>ทะเบียนรถ</label>
                                        <input type="text"
                                            @keypress="$AppUtility.restrictInputLicensePlate($event)"
                                            v-model="form.licenseNumber"
                                            maxlength="10"
                                            placeholder="กกXXXX"
                                            class="square lg"
                                            :class="{ 'invalid': error.licenseNumber }" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>ทะเบียนจังหวัด</label>
                                        <dropdown
                                            v-model="form.licenseProvince"
                                            placeholder="-- เลือกจังหวัด --"
                                            :options="option.licenseProvince"
                                            :classes="{ input: [error.licenseProvince ? 'invalid' : '', 'square', 'lg'] }">
                                        </dropdown>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>ยี่ห้อ</label>
                                        <dropdown
                                            v-model="form.carBrand"
                                            placeholder="ยี่ห้อรถ"
                                            :options="option.carBrand"
                                            :classes="{ input: [error.carBrand ? 'invalid' : '', 'square', 'lg'] }"
                                            @change="changeBrand($event)">
                                        </dropdown>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>รุ่นรถ</label>
                                        <dropdown
                                            v-model="form.carModel"
                                            placeholder="รุ่นรถ"
                                            :options="option.carModel"
                                            :classes="{ input: [error.carModel ? 'invalid' : '', 'square', 'lg'] }">
                                        </dropdown>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 text-center">
                                <button class="btn btn-main btn-submit" @click="saveCar()">บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import UserService from '@/services/userService';
import OptionLicenseProvince from '@/services/staticOption/licenseProvince';

export default {
    data() {
		return {
            isActive: false,
            form: this.initForm(),
            error: this.initError(),
            option: {
                licenseProvince: OptionLicenseProvince.option,
                carBrand: [],
                carModel: []
            }
		};
	},
	props: {
		isDisplay: Boolean,
	},
    async mounted() {
        await this.initCarBrandOption();
    },
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload, car) {
            this.$emit('close', { isReload: isReload, car: car });
        },
        initForm() {
            return {
                licenseNumber: '',
                licenseProvince: '',
                carBrand: '',
                carModel: ''
            };
        },
        initError() {
            return {
                licenseNumber: false,
                licenseProvince: false,
                carBrand: false,
                carModel: false
            };
        },
        async initCarBrandOption() {
            const result = await MasterService.getCarBrand();

            this.option.carBrand = Helper.setupOption(result.data);
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getCarModel(brand);
                loader.hide();

                this.option.carModel = Helper.setupOption(result.data);
            } else {
                this.option.carModel = [];
            }

            this.form.carModel = this.option.carModel.find(x => x.id === this.form.carModel) ? this.form.carModel : '';
        },
        async saveCar() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.addCar(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.hide(true, this.form);
                    this.form = this.initForm();
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถเพิ่มข้อมูลรถได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]?.trim()) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามรถบันทึกข้อมูลรถได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 600px;
}

::v-deep .input-field {
    .form-option {
        input[readonly] {
            background: #fff;
        }
    }
}
</style>